export const getCookie = (name) => {
	// Searching the cookie name with the equals
	let cookieName = name + '=';
	// Getting all cookies as string
	let cookies = document.cookie.split(';');

	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i].trim(); // Removing the trailing spaces

		// Checking if the cookie starts with the searched cookie
		if (cookie.indexOf(cookieName) === 0) {
			// Returning the cookie value
			return cookie.substring(cookieName.length, cookie.length);
		}
	}

	// If it does not exists, returning null
	return null;
};
