import { getCookie } from './cookies';

const cookieName = 'thankYou';

export const giveAccessThankYou = () => {
	// Setting the cookie
	document.cookie = `${cookieName}=true; path=/`;
};

export const checkAccessThankYou = () => {
	
	// Getting the cookies
	let thankYouCookie = getCookie(cookieName);
	if (
		(window.location.pathname.startsWith('/es/gracias/') || window.location.pathname.startsWith('/thank-you/')) &&
		thankYouCookie !== 'true'
	) {
		// Having the url array
		const url = window.location.pathname.split('/');
		// Getting the position of the lang, if it's a lang redirect to it, if not then to root
		const lang = url[1]; // [1] because the url is /es/gracias... or /thank-you, so the split generates the empty first place
		if (lang === 'gracias' || lang === 'thank-you') {
			// If last item removed was thank you, we redirect, it means we will be at the root
			window.location.replace('/'); // Redirect to root
		}
		window.location.replace(`/${lang}`); // Redirect to the lang
	}
	removeAccessThankYou();
};

export const removeAccessThankYou = () => {
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};

export const redirectToThankYou = (lang, thankYouUrl) => {
	const baseUrl = lang === 'es' ? '/es/gracias/' : '/thank-you/'
	giveAccessThankYou()
	window.location.href = `${baseUrl}${thankYouUrl}`
}
